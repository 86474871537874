@import './variables';

#partners-carousel {
  @mixin white-gradient {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  .slick-slider {
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    color: $dark-color;

    &::before,
    &::after {
      @include white-gradient;
      content: "";
      height: 100%;
      position: absolute;
      width: 200px;
      z-index: 2;
    }

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
    }

    &::before {
      left: 0;
      top: 0;
    }

    .slide-track {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .slide {
      vertical-align: middle;
      display: flex !important;
      align-items: center;
      height: 5rem;
      float: right;

      img {
        width: auto;
        max-height: 40px;
        margin: auto;
      }

      &.group {
        border-left: 1px solid #E9ECEF;

      }
    }
  }
}
