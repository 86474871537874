@import './variables';

.workshop {
  .speakers {
    &--item {
      position: relative;
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
      overflow: hidden;
      border: 2px solid #fff;
      cursor: pointer;
    }

    &--avatar {
      width: 100%;
      height: auto;
    }
  }

  &--duration {
    color: $gray-200;
    font-size: .8rem;
  }

  .sponsor-logo {
    &--wrapper {
      width: 6rem;
    }

    &--item {
      width: 100%;
      height: auto;
    }

    &--title {
      display: block;
      width: 100%;
      font-size: .8rem;
      color: $gray-200;

      @media only screen and (min-width: 992px) {
        display: inline;
        width: auto;
      }
    }
  }

  .with-border-bottom {
    position: absolute;
    border-bottom: 1px solid #E9ECEF;
    margin-left: -15px;
    margin-right: 15px;
    width: 100%;

    @media only screen and (min-width: 992px) {
      display: none;

    }
  }

  .with-border {
    border-bottom: 1px solid #E9ECEF;

    @media only screen and (min-width: 992px) {
      border-bottom: 0;
      border-right: 1px solid #E9ECEF;
    }
  }
}

@media (max-width: 992px){
  .container{
    min-width:100% !important;
    max-width:100% !important;
  }
}
