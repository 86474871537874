$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1900px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1240px,
  xxl: 1610px
);

@import '~bootstrap/scss/bootstrap.scss';
@import '~slick-carousel/slick/slick.scss';

body {
  height: 100%;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  font-family: 'Inter', sans-serif;

  &.menu--active {
    overflow: hidden;
  }
}

.desktop-navigation {
  width: 100%;
  position: absolute;
  top: 4rem;
  left: 0;
  right: 0;
  z-index: 10;

  @media only screen and (min-width: 768px) {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
  }

  .navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #212529;
    width: 100%;

    @media only screen and (min-width: 768px) {
      background-color: transparent;
      flex-direction: row;
      justify-content: flex-end;

      height: 100%;
      width: auto;
    }

    &--item {
      line-height: 4.2rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.14);
      width: 90%;
      text-align: left;
      cursor: pointer;

      a {
        color: $light-text-color;
      }

      @media only screen and (min-width: 768px) {
        margin-left: 2.3rem;
        margin-right: 0;
        border-bottom: 0;
        width: auto;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .nav-item-horizontal {
    border-bottom: 1px solid rgba(0, 0, 0, .5);
    margin-bottom: 1rem;

    .active {
      border-color: rgba(0, 0, 0, .5);
    }
  }
}

footer {
  z-index: 20;
  box-shadow: 1px -9px 32px -24px rgba(66, 68, 90, 1);

  a {
    text-decoration: none;
    font-style: normal;
    color: $black;
  }

  .magwet-logo {
    width: 75px;
  }

  @include footer_headers;
}

.copyrights {
  color: $light-grey-color;

  a {
    color: $light-grey-color;
    text-decoration: underline;
  }
}

.btn-grayed {
  color: $light-text-color;
  background-color: rgba(255, 255, 255, 0.64);
  border-bottom: 1px solid rgba(255, 255, 255, .5);
}

.main-congress {
  @include section_buttons;

  .sections {
    margin: 1.5rem 0;
  }
}

.contests {
  &--description {
    color: $light-grey-color;
    max-width: 90%;

  }

  &--lead {
    color: $orange-500;
  }
}


.contact {
  @include footer_headers;

  .sector {
    border-bottom: 1px solid #CED4DA;

    @media only screen and (min-width: 768px) {
      border-bottom: 0;
    }
  }

  &--faq {
    .accordion {

      .card:first-child {
        display: none;
      }

      .btn-link {
        color: $teal-color;
      }

      .card-header {
        background-color: transparent;
        border-bottom: 1px solid #CED4DA;
        box-shadow: none;
      }
    }
  }
}

.watch-now {
  position: relative;

  &--appointments {
    color: $dark-color;
    background-color: $light-color;
    position: absolute;
    width: 40rem;
    transform: translate(-40%, 0);
    border-radius: 6px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);

    li {
      line-height: 140%;
    }

    .live {

      font-size: 1rem;
      font-weight: normal;
    }

    .dark {
      filter: invert(100%) brightness(200%) contrast(100%);
    }
  }
}

.form-group {
  .invalid {
    margin-top: 0.3rem;
    color: #fa3f0f;
  }
}

.text-teal {
  color: $teal-color;
}

.form-check-label {
  font-size: 90%
}

@media only screen and (max-width: 500px) {
  .chat {
    overflow: scroll;
  }
}

.magwet-logo {
  width: 150px;
}

.promo-wrapper {
  background-color: #f7f7f7;

  &.shadow-inset {
    box-shadow: inset 0px 2px 23px -11px rgba(66, 68, 90, 1);
  }
}

// ---------------------------- EVENT PAGE ----------------------------
:root {
  --primary-color: #F46A3E;
  --secondary-color: #242736;
  --secondary-100-color: #00CBFF;
  --light-color: #ffffff;
  --dark-color: #242736;
}

.events {
  .video {
    display: none;
  }

  header .container {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  }

  header {
    background-repeat: no-repeat;

    nav {
      a {
        text-decoration: none;
        color: #fff;
      }
    }

    .nav-item {
      border-bottom: none;
    }
  }

  .primary {
    background-color: var(--primary-color);
    color: #fff;

    &-text {
      color: var(--primary-color);
    }
  }

  .secondary {
    background-color: var(--secondary-color);
    color: #fff;

    &-text {
      color: var(--secondary-color);
    }
  }

  .featured {
    background-color: var(--light-color);
    color: var(--dark-color);
  }

  .promotional {
    background-color: #FFC400;
  }

  .light {
    background-color: #fff;
  }

  .with-arrows, .with-ticks {
    li {
      position: relative;
      padding-inline-start: 1.3ch;
      margin-left: 30px;
      margin-bottom: 20px;
    }

    li::before {
      display: inline-block;
      left: -17px;
      top: -1px;
      position: absolute;
    }
  }

  .with-ticks li::before {
    left: -17px;
    top: 2px;
    position: absolute;
    content: url('../images/tick-bullet.svg');
  }

  .with-arrows li::before {
    content: url('../images/arrow-bullet.svg');

    @include media-breakpoint-up(md) {
      content: url('../images/arrow-bullet.svg');
      left: -20px;
      top: -2px;
    }
  }


  .summary {
    .item {
      min-width: 30%;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
  }

  .bolder {
    font-weight: 700;
  }

  .medium {
    font-weight: 400;
  }

  .text {
    &-xs {
      font-size: 0.9rem;
      line-height: 1.2rem;
    }

    &-base {
      font-size: 1rem;
      line-height: 1.4rem;
    }

    &-l {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    &-xl {
      font-size: 1.25rem;
      line-height: 1.6rem;
    }

    &-xxl {
      font-size: 1.5rem;
      line-height: 1.9rem;
    }

    &-xxxl {
      font-size: 3.125rem;
      line-height: 3.5rem;
    }

    @include media-breakpoint-up(md) {
      &-md-xl {
        font-size: 1.25rem;
        line-height: 1.6rem;
      }
    }
  }

  #congress-days-buttons {
    border: 1px solid #E2E2E2;
    border-left: none;
    border-right: none;

    @include media-breakpoint-down(md) {
      .congress-day-links {
        display: inline-block;
        min-width: 40%;
      }
    }
  }

  .weight {
    &-light {
      font-weight: 400;
    }

    &-base {
      font-weight: 500;
    }

    &-medium {
      font-weight: 700;
    }
  }

  .m {
    &t-8, &my-8 {
      margin-top: 3rem;
    }

    &b-8, &my-8 {
      margin-bottom: 3rem;
    }
  }

  .link {
    &-primary {
      color: var(--primary-color);
    }

    &-secondary {
      color: $orange-500;
    }

    &-light {
      color: var(--light-color);
    }

    &-dark {
      color: var(--dark-color);
    }
  }

  .btn {
    &-primary {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      background-image: none;
      color: #fff;
    }

    &-secondary {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);
      background-image: none;
      color: #fff;
    }

    &-light {
      background-color: var(--light-color);
      color: var(--dark-color);
    }

    &-outline-primary:focus, &-outline-primary.focus {
      box-shadow: none;
    }
  }

  @include media-breakpoint-up(md) {
    br.responsive {
      display: none;
    }
  }

  .appointments {
    background-color: transparent;
  }

  #all-appointments {
    height: auto;
    overflow-y: hidden;
    border-radius: 0;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background: $navy-dark-color;
    }

    &::-webkit-scrollbar-thumb {
      background: #2D284D;
      border-radius: 5px;
    }
  }

  .dark-bg {
    .agenda--wrapper {
      color: #fff;

      .badge {
        background-color: $orange-500;
        text-transform: uppercase;
        color: $dark-color;
        text-align: center;
        border-radius: 0;
        margin-left: 4.4rem;
        @media only screen and (min-width: 992px) {
          margin-left: 7.6rem;
        }
      }
    }

    em.author {
      color: rgba(255, 255, 255, 0.73);
      font-style: normal;
    }
  }

  dl.agenda {
    border-bottom: 1px solid rgba(255, 255, 255, 0.38);
  }

  dl.agenda {
    display: flex;
    flex-direction: row;

    dt {
      white-space: nowrap;
    }
  }

  .past-congress-header {
    color: #fff;
  }

  .appointments-in-past {
    .appointments-by-day {
      .row.post-agenda {
        border-bottom: 1px solid #ccc;

        h6 {
          font-weight: bold;
        }

        .author {
          display: inline-block;
        }
      }

      .btn {
        white-space: nowrap;
      }
    }

    .btn-primary {
      border-color: #A200FF;
      @include gradient(#8200FF, #A200FF);
    }

    .btn-outline-primary {
      color: #A200FF;
      border-color: #A200FF;

      &:hover, &:active {
        color: #fff;
        border-color: #A200FF;
        @include gradient(#8200FF, #A200FF);
      }
    }
  }

  .appointments {
    background-color: $light-color;
    color: $dark-color;

    li.list-group-item {
      background-color: inherit;
      text-align: left;
      margin-left: 3px;
      padding-left: 60px;
      padding-top: 0px;
      font-size: 18px;

      .flex-row {
        margin-bottom: 40px;
        @include media-breakpoint-down(lg) {
          margin-bottom: 5px;
        }
      }

      .ico-thumbnail {
        overflow: hidden;
        width: 80px;
        height: 80px;
        padding: 15px;
        background-color: $orange-500;
      }

      span.appointment-timeframe {
        line-height: 1em;
        margin: -2px 0 5px 0;
        font-weight: 700;
      }

      i {
        color: $orange-500;
        margin-left: -46px;
        margin-top: 31px;
        position: absolute;
        @include media-breakpoint-down(lg) {
          margin-top: 0px;
        }
      }

      h5 {
        font-size: 20px;
        display: inline;
        font-weight: 700;
      }

      .appointment-details {
        font-weight: 700;
        margin-left: 20px;

        .speaker-details {
          font-weight: 400;
          color: #C8C8C8;
          text-transform: uppercase;
          font-size: 15px;
        }
      }
    }
  }


  .appointments {
    li.list-group-item {
      background-color: inherit;
      text-align: left;
      margin-left: 3px;
      padding-left: 60px;
      padding-top: 0;
      font-size: 18px;

      @include media-breakpoint-down(md) {
        padding: 0 !important;
      }

      .flex-row {
        margin-bottom: 40px;
        @include media-breakpoint-down(lg) {
          margin-bottom: 5px;
        }
      }

      .ico-thumbnail {
        overflow: hidden;
        width: 80px;
        height: 80px;
        padding: 15px;
        background-color: $orange-500;
      }

      span.appointment-timeframe {
        line-height: 1em;
        margin: -2px 0 5px 0;
        font-weight: 700;
      }

      i {
        color: $orange-500;
        margin-left: -46px;
        margin-top: 31px;
        position: absolute;
        @include media-breakpoint-down(lg) {
          margin-top: 0px;
        }
      }

      h5 {
        font-size: 20px;
        display: inline;
        font-weight: 700;
      }

      .appointment-details {
        font-weight: 700;
        margin-left: 20px;
        @include media-breakpoint-down(md) {
          margin-left: 0 !important;
        }

        .speaker-details {
          font-weight: 400;
          color: #C8C8C8;
          text-transform: uppercase;
          font-size: 15px;
        }
      }
    }
  }

  .carousel {
    height: 220px;

    &__items {
      height: 90%;
    }
  }

  .sponsors {
    border: 1px solid #E2E2E2;
    border-left: 0;
    border-right: 0;
  }

  .sponsor--logo {
    max-height: 100px;
    max-width: 200px;
  }

  .certificate {
    height: 130px;

    @include media-breakpoint-up(md) {
      height: 200px;
    }
  }

  #tickets {
    .price-box {
      margin: 0 auto;
      width: 288px;
      min-height: 216px;
      text-align: center;
      padding: 0.8125rem;

      .vat {
        color: #878787;
        font-size: 0.8125rem;
        font-weight: 500;
        line-height: 20px;
        text-transform: uppercase;
      }

      .price {
        color: #111;
        font-size: 5rem;
        font-weight: 700;
        line-height: 120%
      }

      @include media-breakpoint-up(md) {
        padding: 1.875rem;
        width: 400px;
        min-height: 248px;

        .price {
          font-size: 3.75rem;
        }
      }
    }
  }

  .faq {
    .card {
      color: $dark-color;

      &-header {
        background-color: $light-color;
        font-size: 110%;
        cursor: pointer;

        &:after {
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          position: absolute;
          right: 1.1rem;
          top: 1.1rem;
          font-size: 1rem;
          content: "\f068";
        }

        &.collapsed {
          border-bottom: 0;

          &:after {
            content: "\f067";
          }
        }
      }

      &-body {
        background-color: $light-color;
        color: $dark-color;
        box-shadow: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125)
      }
    }
  }

  .carousel {
    white-space: nowrap;
    height: 150px;
    position: relative;

    &__items {
      height: 100%;
      position: relative;

      div {
        height: 100%;
        display: grid;
        gap: 1rem;
        justify-items: center;
        grid-template-rows: 80% 1fr;

        .badge {
          font-size: 0.8rem;
          background-color: white;
          box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 15%);
          font-weight: normal;
          color: black;
          padding: 0 1rem;
          justify-self: center;
        }

        a, div {
          height: unset !important;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
}
