@use "sass:map";
@import '../stylesheets/variables.scss';
@import '../stylesheets/layout.scss';

html, body {
  height: 100vh;
}

.content-wrapper {
  position: relative;
}

body {
  background-color: $light-color;
  color: $gray-900;

  &.sign-in {
    background-color: #1D154C;

    main {
      min-height: 600px;
      display: flex;
      align-items: center;
    }
  }
}

.header {
  height: 5rem;
  background: white;
  font-size: 16px;
  display: flex;
  justify-content: center;

  &__content {
    max-width: calc(1180px + 2rem);
    width: 100%;
    padding: 0 1rem;
  }

  a {
    color: black;
    position: relative;
  }

  .nav__buttons {
    .active, a:hover {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $orange-500;
      }
    }
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: inherit;
  }

  &.text-black {
    color: #000;
  }
}

.transparent-dark {
  background-color: #000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.background {
  height: 100%;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  transform: scale(1.03);
  background-size: cover;
  background-color: rgba(0, 0, 0, .5);
  background-position: center;

  &-congress {
    background-image: url('../images/forum-bg.jpg');
    opacity: 0.5;
  }
}

.video {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  filter: brightness(30%);
  background: url('background-hub.jpg') no-repeat center;
  background-size: cover;
  transition: 1s opacity;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  z-index: -1;

  video {
    display: none;
  }

  @media only screen and (min-width: 768px) {
    video {
      display: block;
      position: fixed;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      z-index: -100;
      transform: translateX(-50%) translateY(-50%);
      object-fit: cover;
    }
  }
}

#main-content {
  background-repeat: no-repeat;
  position: relative;
}

.light-text {
  color: $light-color
}

.light-grey-text {
  color: $light-grey-color;
}

.dark-grey-bg {
  background-color: #212529;
  color: $light-color;
}

// ============ old ==============

.title {
  @include title_font;
  font-weight: 700;
}

.divider {
  @include title_divider;
}

.sign_in, .sign_up {
  &--wrapper {
    padding: 2rem;
  }

  &--divider {
    @include title_divider;
    margin-left: auto;
    margin-right: auto;
  }

  &--form {
    background-color: $light-color;
    border-radius: .5rem;
    @media only screen and (min-width: 768px) {
      min-width: 30rem;
    }
  }
}

.sign_up--wrapper {
  max-width: auto;
}

.full-container {
  width: 100%;
}

.light-bg {
  background-color: $light-color;
}

.light-grey-bg {
  background-color: #F1F3F5;

  a {
    color: $teal-color;
  }
}

.dark-bg {
  background-color: #1D154C;
  color: $light-color;
}

.very-dark-bg {
  background-color: #000; //$navy-dark-color;
  color: $light-color;
}

.blue-bg {
  background-color: #002D6D;
  color: $light-color;
}

.yellow-bg {
  background-color: $body-background-color;
}

.gray-bg {
  background-color: #eee;
}

.sponsor {
  width: 100%;
  color: $dark-color;
  align-items: stretch;
  min-height: 8.8rem;

  &.with-bg {
    background-color: #150F38;
  }

  &--link {
    display: block;
    text-decoration: none;
    color: #FFC400;

    &:hover {
      color: #FFC400;
      text-decoration: underline;
    }
  }

  &--logo-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &:hover {
      text-decoration: none;
    }

    &.rank-0, &.rank-1 {
      height: 6.5rem;
    }

    &.rank-2 {
      height: 4.5rem;
    }

    &.rank-3, &.rank-5 {
      height: 3.8rem;
    }
  }

  .logo-img {
    max-width: 98%;
    max-height: 100%;
    height: auto;
  }
}

.sponsor-booth {
  box-shadow: 0 0 18px -2px rgba(0, 0, 0, 0.55);
  overflow: hidden;
  align-items: stretch;
  min-height: 14rem;

  .sponsor {
    &--logo {
      max-width: 100%;
      height: auto;
    }

    &--logo-link {
      &[class*="rank-"] {
        height: auto;
      }
    }
  }

  &_booth {
    position: relative;

    .play-booth {
      position: absolute;
      top: 15%;
      left: 27%;
      z-index: 10;
      transition: all .2s ease-in-out;
      cursor: pointer;
      max-width: 40%;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .booth {
    max-width: 190px;

    @media only screen and (min-width: 768px) {
      max-width: 280px;
    }
  }
}

.congress-card {
  min-height: 11rem;
}

// faq
.accordion {
  & > .card {
    overflow: visible;

    &:not(:last-of-type) {
      margin-bottom: 0.5rem !important;
      border-bottom: 1px solid $light-color;
    }

    & > .card-header {
      margin-bottom: 0;
    }
  }
}

.faq {
  .card {
    color: $light-color;

    &-header {
      background-color: #150F38;
      font-size: 110%;
      cursor: pointer;

      &:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        right: 1.1rem;
        top: 1.1rem;
        font-size: 1rem;
        content: "\f068";
      }

      &.collapsed {
        border-bottom: 0;

        &:after {
          content: "\f067";
        }
      }
    }

    &-body {
      background-color: $light-color;
      color: $dark-color;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid rgba(0, 0, 0, 0.125)
    }
  }
}

.main-congresses {
  .card {
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

    &-image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center 0;
      height: 9rem;
    }

    &-body {
      color: $dark-color;

      h4 {
        a {
          color: $dark-color;
        }
      }

      .pills {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: inline-block;
          background-color: #eee;
          border-radius: 10px;
          font-size: 0.8rem;
          font-weight: 400;
          color: #434343;

        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .card {
      &-image {
        width: 30%;
        height: 11rem;
      }
    }
  }
}

.new_congresses_users_register {
  .agreements {
    label {
      font-size: .8rem;
      color: #434343;
    }

    .congresses_users_register_agreements {
      legend {
        display: none;
      }

      .form-check {
        margin-bottom: 1rem
      }
    }

    .user_select_all {
      label {
        font-weight: bold;
      }
    }
  }
}

.disclaimer {
  font-size: .6rem;
}

aside.promotion {
  position: absolute;
  top: 15rem;
  right: 0;
  width: 120px;
  height: 600px;
}

.faq {
  .card-header {
    h6 {
      line-height: 140%;
    }
  }
}

.e404 {
  font-size: 20rem;
  font-weight: bold;
}

.sponsor--page {
  a {
    text-decoration: none;
  }

  .grey-box {
    background: #f3f3f3;
    margin: 15px 0;
    padding: 25px;

    .title {
      padding-bottom: 20px;
    }

    ul {
      list-style: none;
      font-weight: bold;
      font-size: 0.9rem;
      padding-left: 0px;
      margin-bottom: 0px;

      li {
        padding: 6px 0;

        i {
          color: $dark-blue;
          margin-right: 10px;
        }

        em {
          font-weight: normal;
          color: $dark-grey;
          font-size: 0.8rem;
        }
      }
    }

    a {
      color: $dark-blue;
    }
  }

  .message {
    p {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    #new_congresses_sponsors_message {
      position: relative;
    }

    .agreement {
      font-size: 0.8em;
    }
  }
}

.indicator-layer {
  position: absolute;
  opacity: 0.5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../images/ajax-loader.gif') center no-repeat #eee;
}

.thank-you {
  p {
    font-size: larger;
  }
}

.navbar-toggler {
  color: $light-color
}

.btn-secondary {
  border-color: #A200FF;
  @include gradient(#8200FF, #A200FF);
}

.btn-orange {
  border-color: transparent;
  @include gradient(#F46A3E, #F46A3E);
  color: $light-color;

  &:hover, &:active {
    color: $light-color;
  }
}

.btn-disabled {
  border-color: transparent;
  @include gradient(#C9C9C9, #AEADAD);
  color: $light-color;

  &:hover, &:active {
    color: $light-color;
  }
}

.btn-yellow {
  border-color: transparent;
  background-color: #FFC70940;
}


#player-countdown {
  @media only screen and (min-width: 768px) {
    min-height: 40rem;
  }
  min-height: 20rem;
  color: $light-blue;
  font-family: 'Inter', sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1rem;


  .countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 4rem;

    .days, .hours, .minutes {
      text-align: center;
      text-transform: uppercase;
    }

    span {
      display: block;
      font-size: 1rem;
    }

    .colon {
      text-align: center;
      width: 2rem;
      height: 2rem;
      font-weight: bold;
      margin-top: -2.1rem;
    }
  }
}

.bg {
  &-orange-100 {
    background-color: $orange-100;
  }

  &-orange-500 {
    background-color: $orange-500;
  }

  &-gray-100 {
    background-color: $gray-100;
  }

  &-gray-200 {
    background-color: $gray-200;
  }

  &-gray-800 {
    background-color: $gray-800;
  }

  &-blue-400 {
    background-color: $blue-400;
  }

  &-dark-blue-500 {
    background-color: $dark-blue-500;
  }

  &-teal-500 {
    background-color: $teal-500;
  }

  &-gold-500 {
    background-color: $gold-500;
  }

  &-white {
    background-color: $white;
  }

  &-black {
    background-color: $black;
  }
}

.text {
  &-orange-100 {
    color: $orange-100;
  }

  &-orange-500 {
    color: $orange-500;
  }

  &-gray-800 {
    color: $gray-800;
  }

  &-gray-600 {
    color: $gray-600;
  }

  &-white {
    color: $white;
  }

  &-black {
    color: $black;
  }

  &-titleize:first-letter {
    text-transform: uppercase;
  }
}

.z-index {
  &-negative {
    z-index: -1;
  }

  &-1 {
    z-index: 1;
  }

  &-2 {
    z-index: 2;
  }
}

.flex-even {
  flex: 1;
}

.btn-outlined {
  background-color: transparent;
  color: $orange-500;
  border: 1px solid $orange-500;
  transition: all ease-in-out 0.2s;

  &:hover, &:active {
    background-color: $orange-500;
    color: $white;
  }
}

.main-speaker {
  .agenda--appointment--speaker {
    all: unset;
  }
}

.notices,
.notices .btn {
  font-size: 15px;
}

@media only screen and (max-width: 768px) {
  .notices,
  .notices .btn {
    font-size: 13px;
  }
}

.hub {
  padding-bottom: 2rem;
  font-family: 'Inter', sans-serif;
  display: grid;
  grid-template-rows: auto 1fr;
  justify-items: center;

  &__title {
    font-size: 28px;
    padding-top: 66px;
    font-weight: 600;
  }

  &__congresses {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;
    
    @media (min-width: map.get($grid-breakpoints, "lg")) {
      grid-template-columns: 1fr;
    }
  }

  &__notice {
    color: white;
    display: grid;
    justify-items: center;
    padding-bottom: 2rem;

    h2 {
      font-weight: 400;
      text-align: center;
    }

    hr {
      width: 150px;
      border-top: 2px solid rgba(255, 255, 255, 0.6);
      margin-bottom: 4rem;
    }

    span {
      font-size: 1.2rem;
    }
  }
}

.hub__congresses {
  max-width: calc(1180px + 2rem);
  padding: 0 1rem;

  &__item {
    background: white;
    display: grid;
    border-radius: 0.15rem;
    grid-template-columns: 1fr;
    grid-template-rows: 332px;

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 332px;
    }

    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.15rem 0.15rem 0 0;

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        border-radius: 0.15rem 0 0 0.15rem;
      }
    }

    img {
      border-radius: 0.15rem 0.15rem 0 0;
      object-fit: cover;
      height: 100%;
      width: 100%;

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        border-radius: 0.15rem 0 0 0.15rem;
      }

      &.placeholder {
        object-fit: contain;
      }
    }

    &__hours {
      font-size: 16px;
      font-weight: 300;
      color: $gray-600
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;
      padding: 20px;
      border-top: 1px solid $gray-50;
      
      @media (min-width: map.get($grid-breakpoints, "lg")) {
        border-left: 1px solid $gray-50;
        border-top: none;
      }

      .item__content__title {
        font-size: 26px;
        font-weight: 400;
      }


      &__description {
        display: flex;
        gap: 0.8rem;
        flex-direction: column;
        color: black;

        .content__description__excerpt {
          font-size: 15px;
          color: #111;
        }

        .content__description__title {
          font-size: 26px;
          font-weight: 600;
        }

        a {
          color: black;

          &:hover {
            text-decoration: underline;
            text-decoration-color: $orange-500;
          }
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 1rem;
    color: white;

    > * {
      border-radius: 0.15rem;
      padding: 0.75rem;

      &:hover {
        color: $orange-500;
      }
    }

    .bg-white.text-orange-500 {
      border: 1px solid $orange-500;
    }

    @media (min-width: map.get($grid-breakpoints, "lg")) {
      flex-direction: row;

      > * {
        text-align: start;
      }
    }
  }

  &__footer {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    padding: 2rem 0;

    a {
      display: flex;
      gap: 1rem;

      img {
        transform: scale(0.95, 0.95) translateY(0.5px);
      }

      span {
        font-size: 22px;
        font-weight: 600;
        white-space: nowrap;
      }
    }
  }
}

.archives {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 2rem;
  max-width: calc(1180px + 2rem);
  padding: 0 1rem;

  &__congresses {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr;
    
    @media (min-width: map.get($grid-breakpoints, "sm")) {
      grid-template-columns: 1fr 1fr;
    }
    
    @media (min-width: map.get($grid-breakpoints, "lg")) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.archives__congresses {
  &__year {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: start;
    gap: 1rem;
    align-items: center;
    color: $orange-500;

    span {
      font-size: 20px;
      font-weight: 500;
    }

    .line {
      width: 118px;
      border-bottom: 3px solid $orange-500;
    }
    
  }

  &__item {
    background: white;
    display: grid;
    border-radius: 0.15rem;
    grid-template-rows: 214px 1fr;

    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      border-radius: 0.15rem 0.15rem 0 0;
      object-fit: cover;
      height: 100%;
      width: 100%;

      &.placeholder {
        object-fit: contain;
      }
    }

    .content__play {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      gap: 10px;
      padding-top: 0.4rem;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        line-height: 100%;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      padding: 20px;

      &__hours {
        font-size: 14px;
        font-weight: 400;
        color: $gray-600
      }

      &__description {
        display: flex;
        flex-direction: column;

        a {
          color: black;

          &:hover {
            text-decoration: underline;
            text-decoration-color: $orange-500;
          }
        }

        .description__title {
          font-size: 18px;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    gap: 1rem;
    color: white;

    > * {
      border-radius: 0.15rem;
      padding: 0.75rem;
    }

    .bg-white.text-orange-500 {
      border: 1px solid $orange-500;
    }
  }
}

.nav {
  &__button {
    padding: 0 1rem;
    cursor: pointer;
  }

  &__menu {
    display: none;
    background: white;
    flex-direction: column;
    position: absolute;
    top: 5rem;
    width: 100%;
    height: 100vh;

    a {
      color: black;
      width: 100%;
      text-align: center;
    }
  
    &--active {
      display: flex;
    }
  }
}

footer {
  background: $orange-500;
  color: white;
  display: flex;
  justify-content: center;

  .footer {
    width: 100%;
    max-width: calc(1180px + 2rem);
    padding: 34px 1rem 0 1rem;

    &__navigation {
      display: grid;
      font-size: 16px;
      gap: 1rem;
      grid-template-rows: repeat(3, auto) 6rem;

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
      }

      h6 {
        font-size: 16px;
      }

      &__logo {
        display: flex;
        justify-self: center;
        justify-content: center;
        padding-bottom: 2rem;
        width: 100%;

        @media (min-width: map.get($grid-breakpoints, "lg")) {
          align-items: start;
          justify-content: end;

          img {
            width: 80%;
          }
        }
      }
    }
  
    a, a:hover, h6 {
      color: white;
    }
  
    &__partners {
      border-top: 1px solid rgba(255, 255, 255, 0.4);
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      text-align: center;
  
      &__logos {
        display: grid;
        justify-content: center;
        grid-template-rows: 1.5rem 1.5rem;
        gap: 2rem;
        grid-template-columns: 10rem;
        padding: 1rem 0 2rem 0;
        // height: 100px;
  
        @media (min-width: map.get($grid-breakpoints, "lg")) {
          grid-template-rows: 2rem;
          grid-template-columns: 30% 30%;
          padding: 2rem 0;
        }
  
        a {
          display: flex;
          align-items: center;
          justify-content: center;
  
          &:last-of-type {
            width: 60%;
            justify-self: center;
          }
  
          @media (min-width: map.get($grid-breakpoints, "lg")) {
            &:first-of-type {
              justify-self: end;
            }
    
            &:last-of-type {
              justify-self: start;
              justify-content: end;
            }
          }
  
          img {
            height: 100%;
            padding: 0 !important;
          }
        }
      }
  
      h4 {
        text-transform: uppercase;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 28px;
        letter-spacing: .5em;
        background: $orange-500;
        display: table;
        margin: -14px auto 0px auto;
        padding: 0 25px;
      }
    }

    &__copyrights {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 2rem 1rem;
      gap: 1rem;
      font-size: 14px;

      @media (min-width: map.get($grid-breakpoints, "lg")) {
        flex-direction: row;
      }

      &__socials {
        color: white;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;

        @media (min-width: map.get($grid-breakpoints, "lg")) {
          flex-direction: row;
        }

        &__links {
          display: flex;
          gap: 1rem;
        }
      }
    }
  }
}
