@import './variables';
@import './navigation.scss';

header {
  color: $light-text-color;

  .logo {
    &-link {
      z-index: 150;
    }

    &-img {
      height: 4rem;
      filter: invert(100%) brightness(150%) contrast(100%);
    }
  }

  &.light-bg {
    background-color: $light-color;
  }

  a {
    color: $blue-500;
    text-decoration: none;

    &:hover, &:active {
      color: black;
    }
  }

  &.light-bg {
    color: $blue-500;

    .navigation {

      &--item {
        a {
          color: $blue-500;

          &:hover, &:active {
            color: $orange-500;
          }
        }
      }
    }

    .logo-img {
      filter: invert(0) brightness(0) contrast(100%);
    }

    .ico-menu {
      color: $dark-color;
    }
  }

  .ico-menu {
    display: flex;
    flex-direction: column;
    text-align: center;

    .fas {
      font-size: 1.5rem;
    }
  }
}
