@import './variables';

.congress-nav {
  @media only screen and (min-width: 768px) {
    width: 40rem;
  }

  &--item {
    color: $light-color;
    font-weight: 900;
    background: rgba(255, 255, 255, 0.14);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 6px;
    cursor: pointer;

    a {
      color: $light-color;

      &:hover {
        color: $orange-500
      }
    }
  }
}

.congress-nav {
  background-color: #212529;

  @media only screen and (min-width: 768px) {
    background-color: transparent;
    width: 20rem;
  }

  &-wrapper {
    background-color: #212529;
    @media only screen and (min-width: 768px) {
      background-color: transparent;
    }
  }
}
