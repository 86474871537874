@import './variables';

.sponsors {
  text-align: center;

  &--sponsor-card {
    width: 100%;
    @media only screen and (min-width: 992px) {
      width: 44%;
    }
    @media only screen and (min-width: 1200px) {
      width: 29%;
    }
  }

  .card {
    .rank-label {
      position: relative;
      text-align: center;
      height: 6px;

      strong {
        display: inline-block;
        padding: 0;
        font-weight: normal;
        font-size: 67%;
        text-transform: uppercase;
        letter-spacing: 2px;
        transform: translate(0, -9px);
      }
    }

    .card-body {
      z-index: 1;
    }

    .available-sections {
      text-align: left;
    }
  }

  &--premium {
    .card {
      border-radius: 0;

      &.green-border {
        border-top: 6px solid #00AD8E;
      }
    }
  }

  &--other {
    .col-md-3 {
      margin-bottom: 2rem;
    }
  }

  &--logo-wrapper {
    width: 6rem;
    line-height: 5rem;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #E9ECEF;
    background-color: #fff;
    border-radius: 6px;
    padding: .5rem
  }

  &--logo {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
  }
}
