@import './variables';

.marketplace {
  &--divider {
    border-bottom: 4px solid $gray-50;
  }

  &--logo-img {
    transform: translate(0, 30%);

    @media only screen and (min-width: 992px) {
      transform: translate(0, 130%);
    }
  }
}
